import { requestByGet } from '@/http'

const prefix = '/dealSummary/'
const getApiName = (name: string): string => prefix + name

export const getProductsByTwoHours = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('hour'), data, other)
}

export const getProductsByToday = (data?: {}, other?: {}) => {
  return requestByGet(getApiName('daily'), data, other)
}

export const getCategories = (data?: {}, other?: {}) => {
  return requestByGet('/category/list', data, other)
}
